import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// Example icons for Chatbots
import {
  FaRobot,
  FaComments,
  FaClock,
  FaCloud,
  FaGlobe,
  FaHandsHelping,
  FaCog,
  FaChartLine
} from 'react-icons/fa';

import { DefaultLayout } from '../../components/layout';
import { CaseBlock, Facts, Stage } from '../../components/molecules';
import { Button } from '../../components/atoms';

import './chatbots.scss';

const chatbotsQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "Chatbots" } }) {
      edges {
        node {
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 92, layout: CONSTRAINED)
            }
          }
          imageAlt
        }
      }
    }
    allFile(filter: { name: { regex: "/(stage_c|sanitaer_bot|interim)/" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 92, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

const ChatbotsCase = () => (
  <StaticQuery
    query={chatbotsQuery}
    render={({ allStagesJson, allFile }) => {
      const stageData = allStagesJson.edges[0]?.node;
      const featuresImg = getImage(allFile.edges[0]?.node.childImageSharp);
      const supportImg = getImage(allFile.edges[1]?.node.childImageSharp);

      return (
        <DefaultLayout>
          {/* Stage / Hero Section */}
          <Stage
            modifiers={['gradient', 'case']}
            image={{
              gatsbyImageData: stageData?.imageSrc?.childImageSharp?.gatsbyImageData,
              alt: stageData?.imageAlt || 'Chatbot Image',
            }}
            title={
              <h1
                dangerouslySetInnerHTML={{
                  __html: stageData?.title || 'Chatbots',
                }}
              />
            }
          >
            <div>
              {/* Render any content blocks from the JSON */}
              {stageData?.contentBlocks?.map((block) => (
                <p
                  key={block.id}
                  dangerouslySetInnerHTML={{ __html: block.value }}
                />
              ))}

              {/* Additional marketing copy (optional) */}
              <p>
                Unsere Chatbots bieten Ihnen einen direkten Draht zu Ihren
                Kunden – rund um die Uhr. Sparen Sie Zeit, entlasten Sie Ihr
                Team und steigern Sie gleichzeitig Ihre Kundenzufriedenheit.
              </p>
            </div>
            <div className="learn-more__button">
              <Button url="/kontakt" text="Kontaktieren Sie uns" isPrimary />
            </div>
          </Stage>

          {/* Case Block #1 */}
          <CaseBlock
            title="Rund um die Uhr erreichbar"
            subtitle="Verbesserte Kundenbindung"
            graphic={
              featuresImg && (
                <GatsbyImage
                  image={featuresImg}
                  alt="24/7 Chatbot Support"
                />
              )
            }
          >
            <p>
              Mit unseren KI-gestützten Chatbots ermöglichen Sie Kunden
              Unterstützung zu jeder Tages- und Nachtzeit. Kundenanfragen
              werden automatisch beantwortet, wodurch Ihr Team entlastet und
              die Reaktionszeit drastisch reduziert wird.
            </p>
            <p>
              Dank Automatisierung können Sie sicherstellen, dass keine Anfrage
              unbeachtet bleibt, während Sie Kapazitäten für komplexere
              Supportfälle freigeben.
            </p>
          </CaseBlock>

          {/* Case Block #2 */}
          <CaseBlock
            title="Nahtlose Integration"
            subtitle="Perfekt zugeschnitten auf Ihre Systeme"
            graphic={
              supportImg && (
                <GatsbyImage
                  image={supportImg}
                  alt="Chatbot Integration"
                />
              )
            }
          >
            <p>
              Unsere Chatbots lassen sich mit Ihrem CRM, Ticketing-System oder
              E-Commerce-Plattform verbinden. So wird Ihre digitale
              Kundenkommunikation noch effizienter und zielgerichteter.
            </p>
            <p>
              Ob kleinere Unternehmen oder Grosskonzerne – wir passen unsere
              Lösungen individuell an Ihre bestehenden Workflows an und
              berücksichtigen dabei auch komplexe Unternehmensstrukturen.
            </p>
          </CaseBlock>

          {/* Facts Section */}
          <div className="container">
            <Facts title="Wichtige Funktionen unserer Chatbots">
              <ul>
                <li>Vollautomatische Kundeninteraktionen und FAQs</li>
                <li>Intelligente Weiterleitung komplexer Anfragen</li>
                <li>Integration in CRM, Ticketing und andere Tools</li>
                <li>Skalierbare Lösungen für jedes Unternehmensumfeld</li>
                <li>Spracherkennung und Mehrsprachigkeit</li>
              </ul>
            </Facts>
          </div>

          {/* Features Grid Section */}
          <section className="feature-section container">
            <h2>Die wichtigsten Vorteile auf einen Blick</h2>
            <div className="features-grid">
              <div className="feature">
                <FaRobot className="feature-icon" />
                <h3>Automatisierung</h3>
                <p>
                  Unsere Bots übernehmen bis zu 80–90% aller Standardanfragen
                  und arbeiten effizient an jedem Tag im Jahr.
                </p>
              </div>
              <div className="feature">
                <FaComments className="feature-icon" />
                <h3>Personalisierte Kommunikation</h3>
                <p>
                  Begrüssen Sie Ihre Nutzer freundlich und stellen Sie gezielte
                  Fragen, um schnell zur passenden Lösung zu gelangen.
                </p>
              </div>
              <div className="feature">
                <FaClock className="feature-icon" />
                <h3>24/7 Support</h3>
                <p>
                  Ihr Chatbot schläft nie! Bieten Sie Kunden jederzeit
                  Unterstützung, ohne Ihr Personal rund um die Uhr
                  beanspruchen zu müssen.
                </p>
              </div>
              <div className="feature">
                <FaCloud className="feature-icon" />
                <h3>Flexible Infrastruktur</h3>
                <p>
                  Cloud-basiert oder On-Premises – wählen Sie die für Ihr
                  Unternehmen passende Infrastruktur.
                </p>
              </div>
              <div className="feature">
                <FaGlobe className="feature-icon" />
                <h3>Mehrsprachigkeit</h3>
                <p>
                  Erreichen Sie internationale Kunden, indem Sie mehrere
                  Sprachen anbieten und automatisch erkennen.
                </p>
              </div>
              <div className="feature">
                <FaHandsHelping className="feature-icon" />
                <h3>Team-Entlastung</h3>
                <p>
                  Fokussieren Sie sich auf komplexere Kundenanliegen, während
                  der Chatbot alltägliche Fragen klärt.
                </p>
              </div>
              <div className="feature">
                <FaCog className="feature-icon" />
                <h3>Einfache Anpassung</h3>
                <p>
                  Bauen Sie branchenspezifische Funktionen ein und passen Sie
                  den Chatbot an individuelle Anforderungen an.
                </p>
              </div>
              <div className="feature">
                <FaChartLine className="feature-icon" />
                <h3>Daten-Insights</h3>
                <p>
                  Erfassen Sie wertvolle Nutzerdaten, analysieren Sie Fragen
                  und optimieren Sie Ihre Angebote anhand echter Kundenbedürfnisse.
                </p>
              </div>
            </div>
          </section>
        </DefaultLayout>
      );
    }}
  />
);

export default ChatbotsCase;
